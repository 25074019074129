import React, { useState, useEffect } from "react"
import { Button, Col, ListGroup, Row } from "reactstrap"
import moment from "moment"

import Title from "./Title"
import SchedulableTimes from "./SchedulableTimes"
import {
  ScheduleType,
  Customer,
  User,
  SchedulableTime,
  Prescription,
  CurrentUser,
} from "./types"

type Props = {
  type: ScheduleType
  customer: Customer
  prescriptions: Prescription[]
  currentUser: CurrentUser
}

type SchedulableTimeResponse = {
  schedulable_times: any[]
}

const SelectSchedule: React.FC<Props> = (props) => {
  const { customer, prescriptions, currentUser } = props
  const [type, setType] = useState<ScheduleType>(props.type)
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [unitMinute, setUnitMinute] = useState<Number | null>(null)
  const [schedulableTimes, setSchedulableTimes] = useState<{
    [key: string]: SchedulableTime[]
  }>({})
  const refresh = () => {
    ;(async () => {
      const res = await fetch(
        `/api/schedulable_times?type=${type}&date=${moment(currentDate).format(
          "YYYY-MM-DD"
        )}${unitMinute ? `&minute=${unitMinute}` : ""}`
      )
      const json = (await res.json()) as SchedulableTimeResponse
      let newSchedulableTimes: { [key: string]: SchedulableTime[] } = {}
      json.schedulable_times.forEach((schedulableTime) => {
        const key = moment(schedulableTime.start_at).format("YYYYMMDD")
        if (!newSchedulableTimes[key]) {
          newSchedulableTimes[key] = []
        }
        const startAt = moment(schedulableTime.start_at).toDate()
        const endAt = moment(schedulableTime.end_at).toDate()
        let users = []
        // スケジュール取得はカウンセリングと診療で共通処理のため場合分け
        // 診察のスケジュール取得の際、current_userがcounselorの場合、
        // または処方箋が０の場合は初診対応フラグの立っている医者しか取得しない
        if (type == "medical_examination") {
          users = schedulableTime.users
            .filter((data) => {
              if (
                currentUser.permission_type == "counselor" ||
                !prescriptions ||
                prescriptions.length === 0
              ) {
                if (data.doctor_for_first) return data
              } else {
                return data
              }
            })
            .map((user) => buildUser(user))
        } else if (type == "counseling" || type == "side_effect_examination") {
          users = schedulableTime.users.map((user) => buildUser(user))
        }
        newSchedulableTimes[key].push({
          startAt: startAt,
          endAt: endAt,
          users: users,
        })
      })
      setSchedulableTimes(newSchedulableTimes)
    })()
  }

  const buildUser = (data: any) => {
    const userName = data.immediate_examination
      ? `${data.name} (即時)`
      : data.name
    return {
      id: data.id,
      name: userName,
      permissionType: data.permission_type,
    } as User
  }

  useEffect(() => {
    refresh()
  }, [currentDate, unitMinute, type]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateTypeAndUnitMinute = (
    type: ScheduleType,
    minute: Number | null
  ) => {
    setType(type)
    setUnitMinute(minute)
  }

  const renderSchedulableTimes = () => {
    const schedules = [...Array(7)].map((_, i) => {
      const date = moment(currentDate).add(i, "day")
      const key = date.format("YYYYMMDD")
      const times = schedulableTimes[key] || []
      return (
        <SchedulableTimes
          key={key}
          type={type}
          date={date}
          customer={customer}
          schedulableTimes={times}
          onChange={refresh}
        />
      )
    })
    return (
      <Row>
        <Col md={12}>
          <ListGroup flush>{schedules}</ListGroup>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Title date={currentDate} onChangeDate={setCurrentDate}>
        {type === ScheduleType.counseling ? "カウンセリング" : "診療"}の予約:{" "}
        {customer.name}
      </Title>
      <div className="ml-3">
        {type !== ScheduleType.counseling && (
          <>
            <Button
              onClick={() =>
                updateTypeAndUnitMinute(ScheduleType.medicalExamination, 5)
              }
              className="mr-2"
            >
              5分単位
            </Button>
            <Button
              onClick={() =>
                updateTypeAndUnitMinute(ScheduleType.medicalExamination, 10)
              }
              className="mr-2"
            >
              10分単位
            </Button>
            <Button
              onClick={() =>
                updateTypeAndUnitMinute(ScheduleType.medicalExamination, 20)
              }
              className="mr-2"
            >
              20分単位
            </Button>
            <Button
              onClick={() =>
                updateTypeAndUnitMinute(ScheduleType.sideEffectExamination, 5)
              }
              className="mr-2"
            >
              看護師対応診療
            </Button>
          </>
        )}
      </div>
      {renderSchedulableTimes()}
    </>
  )
}

export default SelectSchedule
