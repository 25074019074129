export const uploadImage = (
  createUploadPolicy: CreateUploadPolicy,
  file: File
) => {
  const policy = createUploadPolicy
  const fd = buildFormData(policy, file)
  return new Promise(function (resolve, reject) {
    fetch(policy.url, { method: "POST", body: fd }).then((response) => {
      if (response.ok) {
        resolve(policy.key)
      } else {
        reject(null)
      }
    })
  })
}

const buildFormData = (policy: CreateUploadPolicy, file: File) => {
  const fd = new FormData()
  for (const name in policy.fields) {
    if (Object.prototype.hasOwnProperty.call(policy.fields, name)) {
      fd.append(name, policy.fields[name])
    }
  }
  fd.append("file", file)
  return fd
}

export type CreateUploadPolicy = {
  size: number
  key: string
  url: string
  fields: any
}
