import React from "react"
import { Form, FormGroup, Input, Label, Button, Col, Row } from "reactstrap"

type Props = {
  user: any
  onChangeHandler: any
  buildImage: any
  submitHandler: any
}

const EditUserProfile: React.FC<Props> = (props) => {
  const {
    id,
    name,
    email,
    phone,
    extensionNumber,
    permissionType,
    isActive,
    meetIn,
    career,
    registrationDate,
    commentFromDoctor,
    profileImageUrl,
    certificateImageUrl,
    doctorForFirst,
    fci,
    sex,
    sideEffectExamination,
    immediateExamination,
    assignmentPriority,
    department,
    employmentStatus,
    reExaminationEnabled,
    intermediateExaminationEnabled,
    increaseDrugTreatable,
  } = props.user
  const cancelEdit = () => {
    window.location.href = `/users/${id}`
  }
  return (
    <Form onSubmit={props.submitHandler}>
      <FormGroup row>
        <Label for="name" sm={2}>
          <span className="text-danger">* </span>名前
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="name"
            onChange={props.onChangeHandler}
            value={name}
            required
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="email" sm={2}>
          <span className="text-danger">* </span>Eメール
        </Label>
        <Col sm={10}>
          <Input
            type="email"
            name="email"
            onChange={props.onChangeHandler}
            value={email}
            required
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="phone" sm={2}>
          電話番号
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="phone"
            onChange={props.onChangeHandler}
            value={phone}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="extensionNumber" sm={2}>
          内線番号
        </Label>
        <Col sm={10}>
          <Input
            type="number"
            min="0"
            max="99999"
            name="extensionNumber"
            onChange={props.onChangeHandler}
            value={extensionNumber}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="permissionType" sm={2}>
          <span className="text-danger">* </span>権限
        </Label>
        <Col sm={10}>
          <Input
            type="select"
            name="permissionType"
            onChange={props.onChangeHandler}
            value={permissionType}
            required
          >
            <option value="admin">管理者</option>
            <option value="doctor">医師</option>
            <option value="counselor">カウンセラー</option>
            <option value="shipper">配送者</option>
            <option value="nurse">看護師</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="isActive" sm={2}>
          アカウント状態
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="isActive"
            onChange={props.onChangeHandler}
            value={isActive}
          >
            <option value="true">有効</option>
            <option value="false">無効</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="doctorForFirst" sm={2}>
          初診対応可能
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="doctorForFirst"
            onChange={props.onChangeHandler}
            value={doctorForFirst.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="reExaminationEnabled" sm={2}>
          再診対応可能
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="reExaminationEnabled"
            onChange={props.onChangeHandler}
            value={reExaminationEnabled.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="sideEffectExamination" sm={2}>
          副作用診療対応可能
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="sideEffectExamination"
            onChange={props.onChangeHandler}
            value={sideEffectExamination.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="intermediateExaminationEnabled" sm={2}>
          中間診療対応可能
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="intermediateExaminationEnabled"
            onChange={props.onChangeHandler}
            value={intermediateExaminationEnabled.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="increaseDrugTreatable" sm={2}>
          増薬希望対応可能
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="increaseDrugTreatable"
            onChange={props.onChangeHandler}
            value={increaseDrugTreatable.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="immediateExamination" sm={2}>
          即時診療担当医
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="immediateExamination"
            onChange={props.onChangeHandler}
            value={immediateExamination.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="fci" sm={2}>
          外用薬
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="fci"
            onChange={props.onChangeHandler}
            value={fci?.toString()}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="sex" sm={2}>
          性別
        </Label>
        <Col sm={10}>
          <Input
            sm={2}
            type="select"
            name="sex"
            onChange={props.onChangeHandler}
            value={sex}
          >
            <option value=""></option>
            <option value="male">男性</option>
            <option value="female">女性</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="meetIn" sm={2}>
          meet in URL
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="meetIn"
            onChange={props.onChangeHandler}
            value={meetIn}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="career" sm={2}>
          経歴
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="career"
            onChange={props.onChangeHandler}
            value={career}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="registrationDate" sm={2}>
          医籍登録年
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="registrationDate"
            onChange={props.onChangeHandler}
            value={registrationDate}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="commentFromDoctor" sm={2}>
          医師からのコメント
        </Label>
        <Col sm={10}>
          <Input
            type="textarea"
            name="commentFromDoctor"
            onChange={props.onChangeHandler}
            value={commentFromDoctor}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="assignmentPriority" sm={2}>
          アサイン優先度
        </Label>
        <Col sm={10}>
          <Input
            type="number"
            name="assignmentPriority"
            step="0.1"
            onChange={props.onChangeHandler}
            value={assignmentPriority}
          ></Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="department" sm={2}>
          部門
        </Label>
        <Col sm={10}>
          <Input
            type="select"
            name="department"
            onChange={props.onChangeHandler}
            value={department}
          >
            <option value=""></option>
            <option value="aga_cfu_member">AGA_Cfu_メンバー</option>
            <option value="aga_cfu_leader">AGA_Cfu_リーダー</option>
            <option value="aga_cr_member">AGA_Cr_メンバー</option>
            <option value="aga_cr_leader">AGA_Cr_リーダー</option>
            <option value="aga_cs_member">AGA_CS_メンバー</option>
            <option value="aga_cs_leader">AGA_CS_リーダー</option>
            <option value="aga_dr_member">AGA_Dr_メンバー</option>
            <option value="aga_dr_leader">AGA_Dr_リーダー</option>
            <option value="aga_ns_member">AGA_Ns_メンバー</option>
            <option value="aga_op_member">AGA_OP_メンバー</option>
            <option value="aga_op_leader">AGA_OP_リーダー</option>
            <option value="aga_doctor_assistant">AGA_医師アシスタント</option>
            <option value="aga_shipping_member">AGA_発送_メンバー</option>
            <option value="aga_pharmacist_member">AGA_薬剤師_メンバー</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="employmentStatus" sm={2}>
          雇用形態
        </Label>
        <Col sm={10}>
          <Input
            type="select"
            name="employmentStatus"
            onChange={props.onChangeHandler}
            value={employmentStatus}
          >
            <option value=""></option>
            <option value="outsourcing">業務委託</option>
            <option value="employment">雇用</option>
          </Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <Label for="profile_image">プロフィール写真</Label>
          <Input
            type="file"
            name="profileImage"
            onChange={props.buildImage}
            accept="image/*"
          ></Input>
        </Col>
        <Col sm={9}>
          <img
            className="profile-img"
            src={profileImageUrl || require("assets/dummy.png")}
            alt="プロフィール画像"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <Label for="certificate_image">オンライン研修修了証</Label>
          <Input
            type="file"
            name="certificateImage"
            onChange={props.buildImage}
            accept="image/*"
          ></Input>
        </Col>
        <Col sm={9}>
          <img
            className="certification-img"
            src={certificateImageUrl || require("assets/dummy.png")}
            alt="プロフィール画像"
          />
        </Col>
      </FormGroup>
      <Row>
        <Col sm={{ size: 10, offset: 2 }}>
          <Button color="primary" className="mr-1" type="submit">
            更新
          </Button>
          <Button onClick={cancelEdit} sm={{ size: 4, offset: 2 }}>
            キャンセル
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default EditUserProfile
