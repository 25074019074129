import React from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import ja from "date-fns/locale/ja"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

registerLocale("ja", ja)

type Props = {
  date: Date
  onChangeDate: (date: Date) => void
}

const Title: React.FC<Props> = (props) => {
  const { date, children } = props
  const dummyUrl = "#"

  const CurrentWeek = (props: any) => (
    <small className="text-dark ml-3">
      <a
        href={dummyUrl}
        onClick={(e) => {
          e.preventDefault()
          props.onClick(e)
        }}
      >
        {moment(date).format("YYYY/MM/DD")} 〜{" "}
        {moment(date).add(1, "week").format("YYYY/MM/DD")}
      </a>
    </small>
  )

  return (
    <h2>
      {children}
      <DatePicker
        selected={date}
        onChange={props.onChangeDate}
        customInput={<CurrentWeek />}
      />
    </h2>
  )
}

export default Title
