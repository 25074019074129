import ZipCode from "../utils/zipcode"

window.addEventListener("load", () => {
  const customerAddressZip = document.querySelector<HTMLInputElement>(
    "#customer_address_zip"
  )
  const customerAddressState = document.querySelector<HTMLInputElement>(
    "#customer_address_state"
  )
  const customerAddressCity = document.querySelector<HTMLInputElement>(
    "#customer_address_city"
  )
  const customerAddressLine1 = document.querySelector<HTMLInputElement>(
    "#customer_address_line1"
  )
  const customerAddressLine2 = document.querySelector<HTMLInputElement>(
    "#customer_address_line2"
  )

  if (
    !(
      customerAddressZip &&
      customerAddressState &&
      customerAddressCity &&
      customerAddressLine1 &&
      customerAddressLine2
    )
  ) {
    return
  }

  customerAddressZip.addEventListener("input", async (e) => {
    const { target } = e
    if (target instanceof HTMLInputElement) {
      const address = await ZipCode.search(target.value)
      customerAddressZip.value = address.zip
      customerAddressState.value = address.region
      customerAddressCity.value = address.locality + address.street
      customerAddressLine1.value = ""
      customerAddressLine2.value = ""
    }
  })
})
