import React, { useState } from "react"
import { Button, Card, CardBody, CardHeader, Col } from "reactstrap"
import moment from "moment"

import NewCounseling from "./NewCounseling"
import NewMedicalExamination from "./NewMedicalExamination"
import NewSideEffectExamination from "./NewSideEffectExamination"
import {
  ScheduleType,
  Customer,
  SchedulableTime as SchedulableTimeModel,
} from "./types"

type Props = {
  type: ScheduleType
  customer: Customer
  schedulableTime: SchedulableTimeModel
  onChange: () => void
}

const SchedulableTime: React.FC<Props> = (props) => {
  const { type, customer, schedulableTime } = props
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)

  const editModalToggle = () => {
    setIsNewModalOpen(!isNewModalOpen)
  }

  const renderTitle = () => {
    const startTime = moment(schedulableTime.startAt).format("HH:mm")
    let endTime = moment(schedulableTime.endAt).format("HH:mm")
    if (
      schedulableTime.endAt.getHours() === 0 &&
      schedulableTime.endAt.getMinutes() === 0
    ) {
      endTime = "24:00"
    }
    return (
      <h5>
        {startTime} 〜 {endTime}
      </h5>
    )
  }

  return (
    <Col sm={6} md={4} lg={3} className="mt-2 mb-2">
      <Card color="light" className="h-100">
        <CardHeader>{renderTitle()}</CardHeader>
        <CardBody>
          <Button color="primary" block onClick={() => setIsNewModalOpen(true)}>
            予約する
            {type === "counseling" ? (
              <>({schedulableTime.users.length})</>
            ) : (
              <>
                (
                {
                  schedulableTime.users.filter(
                    (user) => user.permissionType === "doctor"
                  ).length
                }
                )
              </>
            )}
          </Button>
          {type === ScheduleType.counseling && (
            <NewCounseling
              isOpen={isNewModalOpen}
              customer={customer}
              schedulableTime={schedulableTime}
              toggle={editModalToggle}
              onChange={props.onChange}
            />
          )}
          {type === ScheduleType.medicalExamination && (
            <NewMedicalExamination
              isOpen={isNewModalOpen}
              customer={customer}
              schedulableTime={schedulableTime}
              toggle={editModalToggle}
              onChange={props.onChange}
            />
          )}
          {type === ScheduleType.sideEffectExamination && (
            <NewSideEffectExamination
              isOpen={isNewModalOpen}
              customer={customer}
              schedulableTime={schedulableTime}
              toggle={editModalToggle}
              onChange={props.onChange}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

export default SchedulableTime
