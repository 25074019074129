import React, { useState, useEffect } from "react"
import { Col, ListGroup, Row } from "reactstrap"
import moment from "moment"

import Title from "./Title"
import UserSchedules from "./UserSchedules"
import {
  UserSchedule,
  Counseling,
  MedicalExamination,
  IntermediateMedicalExamination,
  Customer,
} from "./types"

type Props = {
  businessHour: {
    start: number
    end: number
  }
}

type UserSchedulesResponse = {
  user_schedules: any[]
  pagination: {
    prev: string
    current: string
    next: string
  }
}

const DatetimePickerShiftManagement: React.FC<Props> = (props) => {
  const { businessHour } = props
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [userSchedules, setUserSchedules] = useState<{
    [key: string]: UserSchedule[]
  }>({})

  const refresh = () => {
    ;(async () => {
      const res = await fetch(
        `/api/user_schedules?date=${currentDate.toISOString()}`
      )
      const json = (await res.json()) as UserSchedulesResponse
      let newUserSchedules: { [key: string]: UserSchedule[] } = {}
      json.user_schedules.forEach((userSchedule) => {
        const key = moment(userSchedule.start_at).format("YYYYMMDD")
        if (!newUserSchedules[key]) {
          newUserSchedules[key] = []
        }
        newUserSchedules[key].push(buildUserSchedule(userSchedule))
      })
      setUserSchedules(newUserSchedules)
    })()
  }

  const buildCustomer = (data: any) => {
    return {
      id: data.id,
      name: data.name,
    } as Customer
  }

  const buildCounseling = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as Counseling
  }

  const buildMedicalExamination = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as MedicalExamination
  }

  const buildInterMediateMedicalExamination = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as IntermediateMedicalExamination
  }

  const buildUserSchedule = (data: any) => {
    return {
      id: data.id,
      startAt: moment(data.start_at).toDate(),
      endAt: moment(data.end_at).toDate(),
      type: data.schedulable_type,
      counseling: data.counseling ? buildCounseling(data.counseling) : null,
      medicalExamination: data.medical_examination
        ? buildMedicalExamination(data.medical_examination)
        : null,
      intermediateMedicalExamination: data.intermediate_medical_examination
        ? buildInterMediateMedicalExamination(
            data.intermediate_medical_examination
          )
        : null,
    } as UserSchedule
  }

  useEffect(() => {
    refresh()
  }, [currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderUserSchedules = () => {
    const schedules = [...Array(7)].map((_, i) => {
      const date = moment(currentDate).add(i, "day")
      const key = date.format("YYYYMMDD")
      const schedules = userSchedules[key] || []
      return (
        <UserSchedules
          key={key}
          businessHour={businessHour}
          date={date}
          userSchedules={schedules}
          onChange={refresh}
        />
      )
    })
    return (
      <Row>
        <Col md={12}>
          <ListGroup flush>{schedules}</ListGroup>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Title date={currentDate} onChangeDate={setCurrentDate} />
      {renderUserSchedules()}
    </>
  )
}

export default DatetimePickerShiftManagement
