import React, { useState, useEffect } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import ja from "date-fns/locale/ja"
import "react-datepicker/dist/react-datepicker.css"

registerLocale("ja", ja)

type Props = {
  name?: string
  value?: string
  className?: string
}

const DatetimePicker: React.FC<Props> = (props) => {
  const { name, value, className } = props
  const [selected, setSelected] = useState<Date>()

  useEffect(() => {
    if (value) {
      setSelected(new Date(value))
    }
  }, [value])

  return (
    <>
      <input type="hidden" name={name} value={selected?.toISOString()} />
      <DatePicker
        showTimeSelect={true}
        selected={selected}
        onChange={(date: Date) => setSelected(date)}
        dateFormat="yyyy/MM/dd HH:mm"
        timeFormat="HH:mm"
        className={className}
        wrapperClassName="d-block"
      />
    </>
  )
}

export default DatetimePicker
