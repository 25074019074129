import React, { useEffect, useState } from "react"

import Title from "./Title"
import EditUserProfile from "./EditUserProfile"

import { uploadImage, CreateUploadPolicy } from "../../../utils/uploadImage"

import { User } from "./types"

const UserEditProfile: React.FC = () => {
  const requiredItemNames = ["name", "email", "permissionType"]
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [user, setUser] = useState<User>({
    id: "",
    name: "",
    email: "",
    phone: "",
    extensionNumber: null,
    permissionType: "",
    isActive: true,
    meetIn: "",
    career: "",
    registrationDate: "",
    commentFromDoctor: "",
    profileImageUrl: "",
    profileImage: null,
    certificateImageUrl: "",
    doctorForFirst: false,
    fci: false,
    sex: "",
    sideEffectExamination: false,
    immediateExamination: false,
    assignmentPriority: null,
    department: null,
    employmentStatus: null,
    reExaminationEnabled: false,
    intermediateExaminationEnabled: false,
    increaseDrugTreatable: false,
  } as User)

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUser = {
      ...user,
      [e.target.name]: e.target.value,
    }

    validateRequiredItems(newUser)
    setUser(newUser)
  }

  const validateRequiredItems = (user: User) => {
    const errorMessage = "必須項目を埋めてください"
    if (requiredItemNames.some((itemName) => user[itemName] === "")) {
      if (errorMessages.indexOf(errorMessage) === -1) {
        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages,
          errorMessage,
        ])
      }
    } else {
      setErrorMessages((prevErrorMessages) =>
        prevErrorMessages.filter((message) => message !== errorMessage)
      )
    }
  }

  const refresh = () => {
    ;(async () => {
      let urlParamStr = window.location.pathname
      let params = {}
      params = {
        ...params,
        id: urlParamStr.split("/")[2],
      }
      const res = await fetch(`/api/users/${params["id"]}`)
      const json = await res.json()
      const user = buildUser(json.user)
      setUser(user)
    })()
  }

  const buildImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (e.currentTarget.files !== null) {
      const file = e.currentTarget.files[0]
      const url = name + "Url"
      const objectUrl = window.URL.createObjectURL(file)
      const newUser = {
        ...user,
        [name]: file,
        [url]: objectUrl,
      }
      setUser(newUser)
    }
  }

  const buildUser = (data: any) => {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      extensionNumber: data.extension_number,
      permissionType: data.permission_type,
      isActive: data.is_active,
      meetIn: data.meet_in,
      career: data.career,
      registrationDate: data.registration_date,
      commentFromDoctor: data.comment_from_doctor,
      profileImageUrl: data.profile_image_url,
      profileImage: null,
      certificateImageUrl: data.certificate_image_url,
      certificateImage: null,
      doctorForFirst: data.doctor_for_first,
      fci: data.fci,
      sex: data.sex,
      sideEffectExamination: data.side_effect_examination,
      immediateExamination: data.immediate_examination,
      assignmentPriority: data.assignment_priority,
      department: data.department,
      employmentStatus: data.employment_status,
      reExaminationEnabled: data.re_examination_enabled,
      intermediateExaminationEnabled: data.intermediate_examination_enabled,
      increaseDrugTreatable: data.increase_drug_treatable,
    } as User
  }

  const buildCreateUploadPolicy = (data: any) => {
    return {
      key: data.key,
      size: data.size,
      url: data.url,
      fields: data.fields,
    } as CreateUploadPolicy
  }

  const uploadImages = async () => {
    const imagePromise = async (field: string) => {
      if (!user[field]) {
        return user[field + "Url"]
      }

      const image = user[field] as File
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/upload_policies`, {
        method: "POST",
        body: JSON.stringify({
          key: `users/${user.id}`,
          filetype: image.type,
          size: image.size,
        }),
        headers,
      })
      const json = await response.json()
      const imageUrl = await uploadImage(buildCreateUploadPolicy(json), image)
      return imageUrl
    }

    return await Promise.all([
      imagePromise("profileImage"),
      imagePromise("certificateImage"),
    ])
  }

  const submitHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (errorMessages.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
      return
    }
    const imageUrls = await uploadImages()
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement).content
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-CSRF-Token", csrfToken)
    // TODO 他のuserの値を入れる
    const response = await fetch(`/api/users/${user.id}`, {
      method: "PATCH",
      body: JSON.stringify({
        profile_image_url: imageUrls[0],
        certificate_image_url: imageUrls[1],
        name: user.name,
        email: user.email,
        phone: user.phone,
        extension_number: user.extensionNumber,
        permission_type: user.permissionType,
        is_active: user.isActive,
        meet_in: user.meetIn,
        career: user.career,
        registration_date: user.registrationDate,
        comment_from_doctor: user.commentFromDoctor,
        doctor_for_first: user.doctorForFirst,
        fci: user.fci,
        sex: user.sex,
        side_effect_examination: user.sideEffectExamination,
        immediate_examination: user.immediateExamination,
        assignment_priority: user.assignmentPriority,
        department: user.department,
        employment_status: user.employmentStatus,
        re_examination_enabled: user.reExaminationEnabled,
        intermediate_examination_enabled: user.intermediateExaminationEnabled,
        increase_drug_treatable: user.increaseDrugTreatable,
      }),
      headers,
    })
    if (response.ok) {
      window.location.href = `/users/${user.id}`
    } else if (response.status === 422) {
      const json = await response.json()
      window.alert(json.messages.join("\n"))
    } else {
      const json = await response.json()
      window.alert(json.messages?.join("\n") || "予期せぬエラーが発生しました")
    }
  }

  useEffect(() => {
    refresh()
  }, [user.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Title />
      <ul>
        {errorMessages.map((message) => {
          return (
            <li className="text-danger" key={message}>
              {message}
            </li>
          )
        })}
      </ul>
      <EditUserProfile
        user={user}
        onChangeHandler={onChangeHandler}
        buildImage={buildImage}
        submitHandler={submitHandler}
      />
    </>
  )
}

export default UserEditProfile
