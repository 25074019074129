import React, { useEffect, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import Select from "react-select"
import {
  PrescriptionDetail,
  PrescriptionOption,
  Option,
  UseCaseOption,
  MedicineDetailOption,
} from "../../types"

type Props = {
  prescriptionOption: PrescriptionOption
  index: number
  medicalExaminationCategoryId: string
  medicalExaminationCategoryIndex: number
  onChange: (prescriptionDetail: PrescriptionDetail, index: number) => void
}

const PrescriptionDetailForm: React.FC<Props> = (props) => {
  const { prescriptionOption } = props
  const blankOption = { name: null, value: "" }

  const [
    prescriptionDetail,
    setPrescriptionDetail,
  ] = useState<PrescriptionDetail | null>(null)

  const [specAmountOptions, setSpecAmountOptions] = useState<Array<Option>>([])
  const [dosageOptions, setDosageOptions] = useState<Array<Option>>([])
  const [useTimesPerDayOptions, setuseTimesPerDayOptions] = useState<
    Array<Option>
  >([])
  const [useCaseOptions, setuseCaseOptions] = useState<Array<UseCaseOption>>([])
  const [dayOptions, setdayOptions] = useState<Array<Option>>([])

  const index = props.medicalExaminationCategoryIndex * 100 + props.index
  const commonParams = `medical_examination[prescriptions_attributes][0][prescription_details_attributes][[${[
    index,
  ]}]]`

  const nameAttribute = (name: string) => {
    if (prescriptionDetail?.shouldPrescribe) {
      return `${commonParams}${name}`
    }
  }

  const onChangeShouldPrescribe = (value: boolean) => {
    if (value) {
      updatePrescriptionDetail("shouldPrescribe", value)
    } else {
      initialize()
      setSpecAmountOptions([])
    }
  }

  const onChangeSpecAmount = (value: string) => {
    const medicineDetailOption = prescriptionOption.medicine_details.find(
      (detail) => detail.id === value
    )

    if (medicineDetailOption && prescriptionDetail) {
      setPrescriptionDetail({
        ...prescriptionDetail,
        specAmount: medicineDetailOption.spec_amount,
        medicineDetailId: medicineDetailOption.id,
      })
    } else {
      initialize()
    }
  }

  const onChangeUseCases = (value: Array<{ label: string; value: string }>) => {
    updatePrescriptionDetail("useCase", value)
  }

  const onChangeDay = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex]
    if (!prescriptionDetail) {
      return
    }
    setPrescriptionDetail({
      ...prescriptionDetail,
      day: Number(e.target.value),
      dayLabel: selectedOption.textContent,
    })
  }

  const updatePrescriptionDetail = (
    key: string,
    value:
      | boolean
      | string
      | number
      | Array<{ label: string; value: string }>
      | { label: any; value: any }
  ) => {
    if (!prescriptionDetail) {
      return
    }
    setPrescriptionDetail({ ...prescriptionDetail, [key]: value })
  }

  const updateDefaultPrescriptionDetail = (
    medicineDetailOption: MedicineDetailOption
  ) => {
    setPrescriptionDetail({
      ...prescriptionDetail,
      medicineName: prescriptionOption.medicine_name,
      shouldPrescribe: true,
      medicineDetailId: medicineDetailOption.id,
      specAmount: medicineDetailOption.spec_amount,
      specUnit: medicineDetailOption.spec_unit,
      dosageKey: medicineDetailOption.dosage_key,
      dosage: Number(medicineDetailOption.dosage.default),
      dosageUnit: prescriptionOption.dosage_unit,
      useTimesPerDay: Number(medicineDetailOption.use_times_per_day.default),
      useCase: medicineDetailOption.use_case.default.map((value) => ({
        label: value,
        value: value,
      })),
      day: Number(medicineDetailOption.day.default),
      dayLabel: null,
      cautions: prescriptionOption.cautions,
    })
  }

  const initialize = () => {
    setPrescriptionDetail({
      ...prescriptionDetail,
      medicineName: prescriptionOption.medicine_name,
      shouldPrescribe: false,
      medicineDetailId: null,
      specAmount: null,
      specUnit: prescriptionOption.medicine_details[0].spec_unit,
      dosageKey: "",
      dosage: null,
      dosageUnit: prescriptionOption.dosage_unit,
      useTimesPerDay: null,
      useCase: [],
      day: null,
      dayLabel: null,
      cautions: prescriptionOption.cautions,
    })
    setDosageOptions([])
    setuseTimesPerDayOptions([])
    setdayOptions([])
    setuseCaseOptions([])
  }

  useEffect(() => {
    initialize()
  }, [prescriptionOption])

  useEffect(() => {
    if (
      prescriptionDetail?.shouldPrescribe &&
      !prescriptionDetail.medicineDetailId
    ) {
      const options = prescriptionOption.medicine_details.map(
        (medicineDetail) => {
          return { name: medicineDetail.spec_amount, value: medicineDetail.id }
        }
      )
      if (options[0].name !== null) {
        options.unshift(blankOption)
        const medicineDetailOption = prescriptionOption.medicine_details.find(
          (detail) => detail.id === prescriptionOption.default
        )
        if (medicineDetailOption) {
          updateDefaultPrescriptionDetail(medicineDetailOption)
        }
        setSpecAmountOptions(options)
      } else {
        // 選択肢がないものは処方対象がチェックされたタイミングでデフォルト値を埋める
        const medicineDetailOption = prescriptionOption.medicine_details[0]
        updateDefaultPrescriptionDetail(medicineDetailOption)
      }
    }
  }, [prescriptionDetail?.shouldPrescribe])

  useEffect(() => {
    if (!prescriptionDetail) {
      return
    }

    const tagetMedicineDetail = prescriptionOption.medicine_details.find(
      (detail) => detail.id === prescriptionDetail.medicineDetailId
    )
    if (tagetMedicineDetail) {
      setDosageOptions(tagetMedicineDetail.dosage.options)
      setuseTimesPerDayOptions(tagetMedicineDetail.use_times_per_day.options)
      setdayOptions(tagetMedicineDetail.day.options)
      const useCaseOptions: Array<UseCaseOption> = tagetMedicineDetail.use_case.options.map(
        (option) => ({ value: option.value, label: option.name })
      )
      setuseCaseOptions(useCaseOptions)

      const UseCaseDefault = tagetMedicineDetail.use_case.default
        ? tagetMedicineDetail.use_case.default.map((value) => ({
            label: value,
            value: value,
          }))
        : []

      const dayLabel = tagetMedicineDetail.day.options.find(
        (option) => option.value === tagetMedicineDetail.day.default
      )
      setPrescriptionDetail({
        ...prescriptionDetail,
        dosageKey: tagetMedicineDetail.dosage_key,
        dosage:
          tagetMedicineDetail.dosage.default !== null
            ? Number(tagetMedicineDetail.dosage.default)
            : null,
        useTimesPerDay:
          tagetMedicineDetail.use_times_per_day.default !== null
            ? Number(tagetMedicineDetail.use_times_per_day.default)
            : null,
        useCase: UseCaseDefault,
        day:
          tagetMedicineDetail.day.default !== null
            ? Number(tagetMedicineDetail.day.default)
            : null,
        dayLabel: dayLabel === undefined ? null : dayLabel.name,
      })
    }
  }, [prescriptionDetail?.medicineDetailId])

  useEffect(() => {
    if (prescriptionDetail) {
      props.onChange(prescriptionDetail, props.index)
    }
  }, [prescriptionDetail])

  return (
    prescriptionDetail && (
      <Row className="align-items-center mb-3">
        <Input
          value={props.medicalExaminationCategoryId}
          name={nameAttribute("medical_examination_category_id")}
          className="d-none"
          readOnly
        />
        <Col className="p-0 pr-3">{prescriptionDetail.medicineName}</Col>
        <Col
          md={1}
          className="d-flex justify-center align-items-center p-0 pr-3"
        >
          <Input
            type="checkbox"
            checked={prescriptionDetail.shouldPrescribe}
            onChange={(e) => onChangeShouldPrescribe(e.target.checked)}
          />
        </Col>
        <Col className="d-flex align-items-center p-0 pr-3">
          <Input
            value={
              prescriptionDetail.medicineDetailId !== null
                ? prescriptionDetail.medicineDetailId
                : ""
            }
            name={nameAttribute("medicine_detail_id")}
            className="d-none"
            readOnly
          />
          <Input
            type="select"
            value={
              prescriptionDetail.medicineDetailId !== null
                ? prescriptionDetail.medicineDetailId
                : ""
            }
            disabled={specAmountOptions.length == 0}
            className={
              prescriptionDetail.shouldPrescribe &&
              specAmountOptions.length &&
              !prescriptionDetail.specAmount
                ? "is-invalid"
                : ""
            }
            onChange={(e) => onChangeSpecAmount(e.target.value)}
          >
            {specAmountOptions.map((option, index) => (
              <option
                key={index}
                value={option.value !== null ? option.value : ""}
              >
                {option.name}
              </option>
            ))}
          </Input>
          <div className="ml-1">
            {prescriptionOption.medicine_details[0].spec_unit}
          </div>
        </Col>
        <Col className="d-flex align-items-center p-0 pr-3">
          <Input
            type="select"
            value={
              prescriptionDetail.dosage !== null
                ? prescriptionDetail.dosage
                : ""
            }
            disabled={dosageOptions.length == 0}
            onChange={(e) =>
              updatePrescriptionDetail("dosage", Number(e.target.value))
            }
            className="w-65"
            name={nameAttribute(prescriptionDetail.dosageKey)}
          >
            {dosageOptions.map((option, index) => (
              <option
                key={index}
                value={option.value !== null ? option.value : ""}
              >
                {option.name}
              </option>
            ))}
          </Input>
          <div className="ml-1">{prescriptionOption.dosage_unit}</div>
        </Col>
        <Col className="d-flex align-items-center p-0 pr-3">
          <Input
            type="select"
            value={
              prescriptionDetail.useTimesPerDay !== null
                ? prescriptionDetail.useTimesPerDay
                : ""
            }
            disabled={useTimesPerDayOptions.length == 0}
            onChange={(e) =>
              updatePrescriptionDetail("useTimesPerDay", Number(e.target.value))
            }
            className="w-65"
            name={nameAttribute("use_times_per_day")}
          >
            {useTimesPerDayOptions.map((option, index) => (
              <option
                key={index}
                value={option.value !== null ? option.value : ""}
              >
                {option.name}
              </option>
            ))}
          </Input>
          <div className="ml-1">回/日</div>
        </Col>
        <Col md={3} className="p-0 pr-3">
          <Select
            value={prescriptionDetail.useCase}
            isMulti
            options={useCaseOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            name={nameAttribute("usecases[]")}
            isDisabled={useCaseOptions.length == 0}
            onChange={onChangeUseCases}
          />
        </Col>
        <Col md={2} className="p-0 pr-3">
          <Input
            type="select"
            value={
              prescriptionDetail.day !== null ? prescriptionDetail.day : ""
            }
            disabled={dayOptions.length == 0}
            onChange={(e) => onChangeDay(e)}
            name={nameAttribute("day")}
          >
            {dayOptions.map((option, index) => (
              <option
                key={index}
                value={option.value !== null ? option.value : ""}
              >
                {option.name}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    )
  )
}

export default PrescriptionDetailForm
