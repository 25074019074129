export enum ScheduleType {
  counseling = "counseling",
  medicalExamination = "medical_examination",
  sideEffectExamination = "side_effect_examination",
}

export type Customer = {
  id: string
  name: string
}

export type User = {
  id: string
  name: string
  doctor_for_first: boolean
  permissionType: string
}

export type SchedulableTime = {
  startAt: Date
  endAt: Date
  users: User[]
}

export type UserFieldType = {
  label: string
  value: string
}

export type Prescription = {
  id: string
  name: string
  status: string
}

export type CurrentUser = {
  id: string
  name: string
  permission_type: string
}
