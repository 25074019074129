import React, { useState, useEffect } from "react"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import Select from "react-select"
import moment from "moment"

import { Customer, SchedulableTime, UserFieldType } from "./types"

type Props = {
  isOpen: boolean
  customer: Customer
  schedulableTime: SchedulableTime
  onChange: () => void
  toggle: () => void
}

const NewSideEffectExamination: React.FC<Props> = (props) => {
  const { isOpen, customer, schedulableTime } = props
  const [selectedNurse, setSelectedNurse] = useState<UserFieldType | null>(null)
  const [selectedDoctor, setSelectedDoctor] = useState<UserFieldType | null>(
    null
  )
  const [errors, setErrors] = useState<string[]>([])

  const onSubmit = (e) => {
    if (!selectedNurse || !selectedDoctor) {
      return
    }

    e.preventDefault()
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/medical_examinations`, {
        method: "POST",
        body: JSON.stringify({
          customer_id: customer.id,
          reservation_at: schedulableTime.startAt.toISOString(),
          end_at: schedulableTime.endAt.toISOString(),
          doctor_id: selectedDoctor.value,
          nurse_id: selectedNurse.value,
          examination_type: "sideEffect",
        }),
        headers,
      })
      if (response.status === 201 && response.status < 300) {
        window.location.href = `/customers/${customer.id}`
        return
      }
      const json = await response.json()
      setErrors(json.messages)
    })()
  }

  const nurseOptions = () =>
    schedulableTime.users
      .filter((user) => user.permissionType === "nurse")
      .map(
        (user) =>
          ({
            value: user.id,
            label: user.name,
          } as UserFieldType)
      )

  const doctorOptions = () =>
    schedulableTime.users
      .filter((user) => user.permissionType === "doctor")
      .map(
        (user) =>
          ({
            value: user.id,
            label: user.name,
          } as UserFieldType)
      )

  useEffect(() => {
    setSelectedNurse(null)
    setSelectedDoctor(null)
  }, [schedulableTime])

  return (
    <Modal isOpen={isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>診療予約</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {errors.map((error) => (
            <Alert color="warning" key={error}>
              {error}
            </Alert>
          ))}
          <FormGroup row>
            <Label md={4}>顧客</Label>
            <Col md={8}>
              <p className="form-control-plaintext">{customer.name}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>予約日</Label>
            <Col md={8}>
              <p className="form-control-plaintext">
                {moment(schedulableTime.startAt).format("YYYY/MM/DD")}
              </p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>予約時間</Label>
            <Col md={8}>
              <p className="form-control-plaintext">
                {moment(schedulableTime.startAt).format("HH:mm")} 〜{" "}
                {moment(schedulableTime.endAt).format("HH:mm")}
              </p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>看護師</Label>
            <Col md={8}>
              <Select
                value={selectedNurse}
                options={nurseOptions()}
                onChange={(value) => setSelectedNurse(value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>医師</Label>
            <Col md={8}>
              <Select
                value={selectedDoctor}
                options={doctorOptions()}
                onChange={(value) => setSelectedDoctor(value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => props.toggle()}>
            キャンセル
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={!selectedNurse || !selectedDoctor}
          >
            作成
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default NewSideEffectExamination
