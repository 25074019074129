import $ from "jquery"

class FileImageControl {
  static init() {
    // 画像クリックでモーダル表示
    $(".target-image").click(function () {
      const widthDefault = $(this).width()
      const heightDefault = $(this).height()

      let tmpThis = $.extend(true, {}, $(this))
      tmpThis.width(960)
      tmpThis.height(640)

      $("#display-modal").html(tmpThis.prop("outerHTML"))
      // モーダル表示の画像の上が見切れるため位置修正
      $("#display-modal").animate(
        { top: "30px" },
        { duration: "100", easing: "linear" }
      )
      $("#display-modal").fadeIn(200)
      // 元要素のサイズまで変わってしまうため、元のサイズに戻す
      $(this).width(widthDefault)
      $(this).height(heightDefault)

      return false
    })

    // モーダル非表示
    $("#display-modal").click(function () {
      $("#display-modal").fadeOut(200)
      return false
    })
  }
}

export default FileImageControl
