export type Customer = {
  id: string
  name: string
}

export type User = {
  id: string
  name: string
}

export type Counseling = {
  id: string
  status: string
  customer: Customer
}

export type MedicalExamination = {
  id: string
  status: string
  customer: Customer
}

export type SideEffectExamination = {
  id: string
  status: string
  customer: Customer
}

export type UnableToPrescribeMedicalExamination = {
  id: string
  status: string
  customer: Customer
}

export type IntermediateMedicalExamination = {
  id: string
  status: string
  customer: Customer
}

export enum UserScheduleType {
  counseling = "Counseling",
  medicalExamination = "MedicalExamination",
  unableToPrescribeMedicalExamination = "UnableToPrescribeMedicalExamination",
  sideEffectExamination = "SideEffectExamination",
  intermediateMedicalExamination = "IntermediateMedicalExamination",
}

export type UserSchedule = {
  id: string
  userId: string
  startAt: Date
  endAt: Date
  type: UserScheduleType | null
  counseling: Counseling | null
  medicalExamination: MedicalExamination | null
  unableToPrescribeMedicalExamination: UnableToPrescribeMedicalExamination | null
  sideEffectExamination: SideEffectExamination | null
  intermediateMedicalExamination: IntermediateMedicalExamination | null
  user: User | null
}
