let CACHE = []
const BASE_URL = "https://yubinbango.github.io/yubinbango-data/data"
const REGION = [
  "",
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
]

type ZipCodeResponse = {
  zip: string
  regionId: number
  region: string
  locality: string
  street: string
  extended: string
}

class ZipCode {
  static search(inputVal: string) {
    return new Promise<ZipCodeResponse>(async (resolve, reject) => {
      if (inputVal === "") {
        return
      }

      // 全角の数字を半角に変換 ハイフンが入っていても数字のみの抽出
      const val = inputVal.replace(/[０-９]/g, (s: string) =>
        String.fromCharCode(s.charCodeAt(0) - 65248)
      )
      const matchData = val.match(/\d/g)
      if (matchData === null || matchData.length !== 7) {
        return
      }
      const zipCode = matchData.join("")
      const res = await this.getAddr(zipCode)
      resolve(res)
    })
  }

  static selectAddr(formatedZip, addr: any[]) {
    if (addr && addr[0] && addr[1]) {
      return {
        zip: formatedZip,
        regionId: addr[0],
        region: REGION[addr[0]],
        locality: addr[1],
        street: addr[2] || "",
        extended: addr[3] || "",
      } as ZipCodeResponse
    }
  }

  static jsonp(url: string, callback) {
    window["$yubin"] = callback
    const scriptTag = document.createElement("script")
    scriptTag.setAttribute("type", "text/javascript")
    scriptTag.setAttribute("charset", "UTF-8")
    scriptTag.setAttribute("src", url)
    document.head.appendChild(scriptTag)
  }

  static getAddr(zipCode: string) {
    return new Promise<ZipCodeResponse>((resolve) => {
      const formatedZip = `${zipCode.substr(0, 3)}-${zipCode.substr(3, 4)}`
      const top3 = zipCode.substr(0, 3)
      // 郵便番号上位3桁でキャッシュデータを確認
      if (top3 in CACHE && zipCode in CACHE[top3]) {
        const zipCodeResponse = this.selectAddr(
          formatedZip,
          CACHE[top3][zipCode]
        )

        if (zipCodeResponse) resolve(zipCodeResponse)
        return
      }
      this.jsonp(`${BASE_URL}/${top3}.js`, (data) => {
        CACHE[top3] = data
        const zipCodeResponse = this.selectAddr(formatedZip, data[zipCode])
        if (zipCodeResponse) resolve(zipCodeResponse)
      })
    })
  }
}

export default ZipCode
