import React, { useState, useEffect } from "react"
import { Col, ListGroup, Row } from "reactstrap"
import moment from "moment"
import ReactPaginate from "react-paginate"
import Title from "./Title"
import UserSchedules from "./UserSchedules"
import {
  UserSchedule,
  Counseling,
  MedicalExamination,
  Customer,
  User,
  IntermediateMedicalExamination,
  UnableToPrescribeMedicalExamination,
} from "./types"

type Props = {
  businessHour: {
    start: number
    end: number
  }
}

type UserSchedulesResponse = {
  user_schedules: any[]
  users: any[]
  pagination: {
    prev: string
    current: string
    next: string
  }
}

const DatetimePickerSuperShiftManagement: React.FC<Props> = (props) => {
  const { businessHour } = props
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [userSchedules, setUserSchedules] = useState<{
    [key: string]: UserSchedule[]
  }>({})
  const [users, setUsers] = useState<User[]>([])
  const [activePage, setCurrentPage] = useState(1)

  const refresh = () => {
    ;(async () => {
      let urlParamStr = window.location.search
      if (urlParamStr) {
        urlParamStr = urlParamStr.substring(1)
        let params = {}
        urlParamStr.split("&").forEach((param) => {
          const temp = param.split("=")
          params = {
            ...params,
            [temp[0]]: temp[1],
          }
        })
        if (params["name"] || params["email"] || params["permission_type"]) {
          const res = await fetch(
            `/api/super_user_schedules?date=${currentDate.toISOString()}&crm_super_user_schedule_search_form%5Bname%5D=${
              params["name"]
            }&crm_super_user_schedule_search_form%5Bemail%5D=${
              params["email"]
            }&crm_super_user_schedule_search_form%5Bpermission_type%5D=${
              params["permission_type"]
            }&commit=${params["commit"]}`
          )
          const json = (await res.json()) as UserSchedulesResponse
          setUsers(json.users)
          let newUserSchedules: { [key: string]: UserSchedule[] } = {}
          json.user_schedules.forEach((userSchedule) => {
            const key = userSchedule.user_id
            if (!newUserSchedules[key]) {
              newUserSchedules[key] = []
            }
            newUserSchedules[key].push(buildUserSchedule(userSchedule))
          })
          setUserSchedules(newUserSchedules)
        }
      }
    })()
  }

  const buildCustomer = (data: any) => {
    return {
      id: data.id,
      name: data.name,
    } as Customer
  }

  const buildUser = (data: any) => {
    return {
      id: data.id,
      name: data.name,
    } as User
  }

  const buildCounseling = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as Counseling
  }

  const buildMedicalExamination = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as MedicalExamination
  }

  const buildUnableToPrescribeMedicalExamination = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as UnableToPrescribeMedicalExamination
  }

  const buildInterMediateMedicalExamination = (data: any) => {
    return {
      id: data.id,
      status: data.status,
      customer: buildCustomer(data.customer),
    } as IntermediateMedicalExamination
  }

  const buildUserSchedule = (data: any) => {
    return {
      id: data.id,
      userId: data.user_id,
      startAt: moment(data.start_at).toDate(),
      endAt: moment(data.end_at).toDate(),
      type: data.schedulable_type,
      counseling: data.counseling ? buildCounseling(data.counseling) : null,
      medicalExamination: data.medical_examination
        ? buildMedicalExamination(data.medical_examination)
        : null,
      unableToPrescribeMedicalExamination: data.unable_to_prescribe_medical_examination
        ? buildUnableToPrescribeMedicalExamination(
            data.unable_to_prescribe_medical_examination
          )
        : null,
      intermediateMedicalExamination: data.intermediate_medical_examination
        ? buildInterMediateMedicalExamination(
            data.intermediate_medical_examination
          )
        : null,
      user: buildUser(data.user),
    } as UserSchedule
  }

  useEffect(() => {
    refresh()
  }, [currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderUserSchedules = () => {
    const usersPerPage = 20
    const indexOfLastUser = activePage * usersPerPage
    const indexOfFirstUser = indexOfLastUser - usersPerPage
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser)
    const pageCount = users.length / usersPerPage

    const renderUsers = currentUsers.map((user, i) => {
      const date = moment(currentDate)
      const key = user.id
      const schedules = userSchedules[key] || []
      return (
        <UserSchedules
          user={user}
          key={key}
          businessHour={businessHour}
          date={date}
          userSchedules={schedules}
          onChange={refresh}
        />
      )
    })

    const handlePageChange = (data: { selected: number }) => {
      setCurrentPage(data.selected + 1)
    }

    return (
      <>
        <Row>
          <Col md={12}>
            <ListGroup flush>{renderUsers}</ListGroup>
          </Col>
        </Row>
        <div>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel=">"
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            renderOnZeroPageCount={() => null}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Title date={currentDate} onChangeDate={setCurrentDate} />
      {renderUserSchedules()}
    </>
  )
}

export default DatetimePickerSuperShiftManagement
