import Rails from "@rails/ujs"
import "channels"

import "crm"
import "crm/customers_edit"
import "stylesheets/crm"

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

Rails.start()

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.RAILS_ENV,
  tracesSampleRate: 0.3,
})
