import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
  Row,
} from "reactstrap"
import moment from "moment"

import Confirm from "../Confirm"
import EditUserSchedule from "./EditUserSchedule"
import { UserSchedule as UserScheduleModel, UserScheduleType } from "./types"

type Props = {
  businessHour: {
    start: number
    end: number
  }
  userSchedule: UserScheduleModel
  onChange: () => void
}

const UserSchedule: React.FC<Props> = (props) => {
  const { userSchedule, businessHour } = props
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const deleteUserSchedule = () => {
    setIsDeleteConfirmOpen(false)
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/user_schedules/${userSchedule.id}`, {
        method: "DELETE",
        headers,
      })
      if (response.status >= 200 && response.status < 300) {
        props.onChange()
        return
      }
      const json = await response.json()
      alert(json.errors.join("\n"))
    })()
  }

  const editModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  const renderTitle = () => {
    const startTime = moment(userSchedule.startAt).format("HH:mm")
    let endTime = moment(userSchedule.endAt).format("HH:mm")
    if (
      userSchedule.endAt.getHours() === 0 &&
      userSchedule.startAt.getMinutes() === 0
    ) {
      endTime = "24:00"
    }
    return (
      <h5>
        {startTime} 〜 {endTime}
      </h5>
    )
  }

  return (
    <Col sm={6} md={4} lg={3} className="mt-2 mb-2">
      <Card color="light" className="h-100">
        <CardHeader>
          <Row>
            <Col xs={10}>{renderTitle()}</Col>
            <Col xs={2} className="float-right">
              {userSchedule.type === null && (
                <Nav>
                  <UncontrolledDropdown dis>
                    <DropdownToggle color="">
                      <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setIsEditModalOpen(true)}>
                        編集
                        <EditUserSchedule
                          businessHour={businessHour}
                          isOpen={isEditModalOpen}
                          userSchedule={userSchedule}
                          toggle={editModalToggle}
                          onChange={props.onChange}
                        />
                      </DropdownItem>
                      <DropdownItem
                        className="text-danger"
                        onClick={() => setIsDeleteConfirmOpen(true)}
                      >
                        削除
                        <Confirm
                          isOpen={isDeleteConfirmOpen}
                          title="削除の確認"
                          onCancel={() => setIsDeleteConfirmOpen(false)}
                          onOk={() => deleteUserSchedule()}
                        >
                          本当にシフトを削除しますか？
                        </Confirm>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {(() => {
            if (
              userSchedule.type === UserScheduleType.counseling &&
              userSchedule.counseling
            ) {
              const counseling = userSchedule.counseling
              return (
                <a href={`/counselings/${counseling.id}`}>
                  カウンセリング: {counseling.customer.name}({counseling.status}
                  )
                </a>
              )
            } else if (
              userSchedule.type === UserScheduleType.medicalExamination &&
              userSchedule.medicalExamination
            ) {
              const medicalExamination = userSchedule.medicalExamination
              return (
                <a href={`/medical_examinations/${medicalExamination.id}`}>
                  診療: {medicalExamination.customer.name} (
                  {medicalExamination.status})
                </a>
              )
            } else if (
              userSchedule.type === UserScheduleType.sideEffectExamination &&
              userSchedule.sideEffectExamination
            ) {
              const sideEffectExamination = userSchedule.sideEffectExamination
              return (
                <a
                  href={`/side_effect_examinations/${sideEffectExamination.id}`}
                >
                  副作用診療: {sideEffectExamination.customer.name} (
                  {sideEffectExamination.status})
                </a>
              )
            } else if (
              userSchedule.type ===
                UserScheduleType.intermediateMedicalExamination &&
              userSchedule.intermediateMedicalExamination
            ) {
              const intermediateMedicalExamination =
                userSchedule.intermediateMedicalExamination
              return (
                <a
                  href={`/intermediate_medical_examinations/${intermediateMedicalExamination.id}`}
                >
                  中間診療: {intermediateMedicalExamination.customer.name}(
                  {intermediateMedicalExamination.status})
                </a>
              )
            } else if (
              userSchedule.type ===
                UserScheduleType.unableToPrescribeMedicalExamination &&
              userSchedule.unableToPrescribeMedicalExamination
            ) {
              const unableToPrescribeMedicalExamination =
                userSchedule.unableToPrescribeMedicalExamination
              return (
                <a
                  href={`/unable_to_prescribe_medical_examinations/${unableToPrescribeMedicalExamination.id}`}
                >
                  再診処方不可患者診療:{" "}
                  {unableToPrescribeMedicalExamination.customer.name}(
                  {unableToPrescribeMedicalExamination.status})
                </a>
              )
            }
            return "予定なし"
          })()}
        </CardBody>
      </Card>
    </Col>
  )
}

export default UserSchedule
