import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

type Props = {
  isOpen: boolean
  title: string
  okButtonTitle?: string
  cancelButtonTitle?: string
  onCancel: () => void
  onOk: () => void
}

const Confirm: React.FC<Props> = (props) => {
  const { isOpen, title, okButtonTitle, cancelButtonTitle, children } = props

  const onCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const onOkClick = () => {
    if (props.onOk) {
      props.onOk()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onCancelClick()}>
          {cancelButtonTitle || "キャンセル"}
        </Button>
        <Button color="primary" onClick={() => onOkClick()}>
          {okButtonTitle || "はい"}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
