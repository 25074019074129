import React, { useState } from "react"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import moment, { Moment } from "moment"
import { User } from "./types"

type Props = {
  businessHour: {
    start: number
    end: number
  }
  user: User
  date: Moment
  className?: string
  onChange: () => void
}

const NewUserSchedule: React.FC<Props> = (props) => {
  const { businessHour, date, className, user } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startAt, setStartAt] = useState<Date>(new Date())
  const [startHour, setStartHour] = useState(0)
  const [startMinute, setStartMinute] = useState(0)
  const [endAt, setEndAt] = useState<Date>(new Date())
  const [endHour, setEndHour] = useState(0)
  const [endMinute, setEndMinute] = useState(0)
  const [errors, setErrors] = useState<string[]>([])

  const showModal = () => {
    const startHour = Math.max(businessHour.start, 9)
    setStartHour(startHour)
    setStartMinute(0)
    setStartAt(new Date(date.year(), date.month(), date.date(), startHour, 0))
    const endHour = Math.min(businessHour.end, 12)
    setEndHour(endHour)
    setEndMinute(0)
    setEndAt(new Date(date.year(), date.month(), date.date(), endHour, 0))
    setIsModalOpen(true)
  }

  const toggleModal = () => {
    if (isModalOpen) {
      setErrors([])
    }
    setIsModalOpen(!isModalOpen)
  }

  const onChangeStartHour = (hour: number) => {
    const newStartAt = new Date(
      date.year(),
      date.month(),
      date.date(),
      hour,
      startMinute
    )
    if (endAt <= newStartAt) {
      const newEndAt = moment(newStartAt).add(5, "minutes").toDate()
      setEndHour(newEndAt.getHours())
      setEndMinute(newEndAt.getMinutes())
      setEndAt(newEndAt)
    }
    setStartHour(hour)
    setStartAt(newStartAt)
  }

  const onChangeStartMinute = (minute: number) => {
    const newStartAt = new Date(
      date.year(),
      date.month(),
      date.date(),
      startHour,
      minute
    )
    if (endAt <= newStartAt) {
      const newEndAt = moment(newStartAt).add(5, "minutes").toDate()
      setEndHour(newEndAt.getHours())
      setEndMinute(newEndAt.getMinutes())
      setEndAt(newEndAt)
    }
    setStartMinute(minute)
    setStartAt(newStartAt)
  }

  const onChangeEndHour = (hour: number) => {
    // NOTE: 営業終了時間を超えた範囲を選択できないようにする
    //       24時を指定した場合日付は次の日のため基準日はstartAtから参照する
    const minutes = hour === businessHour.end ? 0 : endMinute
    let newEndAt = new Date(
      startAt.getFullYear(),
      startAt.getMonth(),
      startAt.getDate(),
      hour,
      minutes
    )
    if (newEndAt <= startAt) {
      newEndAt = moment(startAt).add(5, "minutes").toDate()
    }
    setEndHour(hour)
    setEndMinute(newEndAt.getMinutes())
    setEndAt(newEndAt)
  }

  const onChangeEndMinute = (minute: number) => {
    const newEndAt = new Date(
      date.year(),
      date.month(),
      date.date(),
      endHour,
      minute
    )
    setEndMinute(minute)
    setEndAt(newEndAt)
  }

  const startHourOptions = () =>
    Array.from(
      { length: businessHour.end - businessHour.start },
      (_, k) => k + businessHour.start
    )

  const endHourOptions = () => {
    if (startMinute === 55) {
      return Array.from(
        { length: businessHour.end - startHour },
        (_, k) => k + startHour + 1
      )
    }
    return Array.from(
      { length: businessHour.end - startHour + 1 },
      (_, k) => k + startHour
    )
  }

  const endMinuteOptions = () => {
    if (endHour === businessHour.end) {
      return [0]
    } else if (startHour === endHour) {
      return Array.from(
        { length: 12 - startMinute / 5 - 1 },
        (_, k) => (k + 1) * 5 + startMinute
      )
    } else {
      return Array.from({ length: 12 }, (_, k) => k * 5)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(
        `/api/super_user_schedules/${user.id}/user_schedule`,
        {
          method: "POST",
          body: JSON.stringify({
            start_at: startAt.toISOString(),
            end_at: endAt.toISOString(),
          }),
          headers,
        }
      )
      if (response.status >= 200 && response.status < 300) {
        setIsModalOpen(false)
        props.onChange()
        return
      }
      const json = await response.json()
      setErrors(json.errors)
    })()
  }

  return (
    <>
      <Button className={className} color="primary" onClick={() => showModal()}>
        <span className="icon">
          <i className="fas fa-plus"></i>
        </span>
        <span className="text">シフト作成</span>
      </Button>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{user.name}のシフト作成</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            {errors.map((error) => (
              <Alert color="warning" key={error}>
                {error}
              </Alert>
            ))}
            <FormGroup row>
              <Label md={4}>日付</Label>
              <Col md={8}>
                <p className="form-control-plaintext">
                  {date.format("YYYY/MM/DD")}
                </p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>開始時刻</Label>
              <Col md={8}>
                <Row>
                  <Col xs={5}>
                    <Input
                      type="select"
                      value={startHour}
                      onChange={(e) => onChangeStartHour(+e.target.value)}
                    >
                      {startHourOptions().map((i) => (
                        <option key={i} value={i}>
                          {`0${i}`.slice(-2)}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>
                    <Input
                      type="select"
                      value={startMinute}
                      onChange={(e) => onChangeStartMinute(+e.target.value)}
                    >
                      {Array.from({ length: 12 }, (_, k) => k * 5).map((i) => (
                        <option key={i} value={i}>
                          {`0${i}`.slice(-2)}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>終了時刻</Label>
              <Col md={8}>
                <Row>
                  <Col md={5}>
                    <Input
                      type="select"
                      value={endHour}
                      onChange={(e) => onChangeEndHour(+e.target.value)}
                    >
                      {endHourOptions().map((i) => (
                        <option key={i} value={i}>
                          {`0${i}`.slice(-2)}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col md={5}>
                    <Input
                      type="select"
                      value={endMinute}
                      onChange={(e) => onChangeEndMinute(+e.target.value)}
                    >
                      {endMinuteOptions().map((i) => (
                        <option key={i} value={i}>
                          {`0${i}`.slice(-2)}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal()}>
              キャンセル
            </Button>
            <Button type="submit" color="primary">
              作成
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

export default NewUserSchedule
