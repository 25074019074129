import React from "react"
import { ListGroupItem, Row } from "reactstrap"
import { Moment } from "moment"

import NewUserSchedule from "./NewUserSchedule"
import UserSchedule from "./UserSchedule"
import { UserSchedule as UserScheduleModel, User } from "./types"

type Props = {
  businessHour: {
    start: number
    end: number
  }
  user: User
  date: Moment
  userSchedules: UserScheduleModel[]
  onChange: () => void
}

const UserSchedules: React.FC<Props> = (props) => {
  const { businessHour, date, userSchedules, user } = props

  return (
    <>
      <ListGroupItem>
        <h3 className="mr-4">
          {user.name}
          <NewUserSchedule
            className="ml-4"
            businessHour={businessHour}
            date={date}
            user={user}
            onChange={props.onChange}
          />
        </h3>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          {userSchedules.map((userSchedule) => (
            <UserSchedule
              businessHour={businessHour}
              key={userSchedule.id}
              userSchedule={userSchedule}
              user={user}
              onChange={props.onChange}
            />
          ))}
        </Row>
      </ListGroupItem>
    </>
  )
}

export default UserSchedules
