import React, { useState, useEffect } from "react"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import Select from "react-select"
import moment from "moment"

import { Customer, SchedulableTime, UserFieldType } from "./types"

type Props = {
  isOpen: boolean
  customer: Customer
  schedulableTime: SchedulableTime
  onChange: () => void
  toggle: () => void
}

const NewCounseling: React.FC<Props> = (props) => {
  const { isOpen, customer, schedulableTime } = props
  const [selectedUser, setSelectedUser] = useState<UserFieldType | null>(null)
  const [errors, setErrors] = useState<string[]>([])

  const onSubmit = (e) => {
    if (!selectedUser) {
      return
    }

    e.preventDefault()
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/counselings`, {
        method: "POST",
        body: JSON.stringify({
          customer_id: customer.id,
          reservation_at: schedulableTime.startAt.toISOString(),
          counselor_id: selectedUser.value,
        }),
        headers,
      })
      if (response.status === 201 && response.status < 300) {
        window.location.href = `/customers/${customer.id}`
        return
      }
      const json = await response.json()
      setErrors(json.messages)
    })()
  }

  const userOptions = () =>
    schedulableTime.users.map(
      (user) =>
        ({
          value: user.id,
          label: user.name,
        } as UserFieldType)
    )

  useEffect(() => {
    setSelectedUser(null)
  }, [schedulableTime])

  return (
    <Modal isOpen={isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>カウンセリング予約</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {errors.map((error) => (
            <Alert color="warning" key={error}>
              {error}
            </Alert>
          ))}
          <FormGroup row>
            <Label md={4}>顧客</Label>
            <Col md={8}>
              <p className="form-control-plaintext">{customer.name}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>予約日</Label>
            <Col md={8}>
              <p className="form-control-plaintext">
                {moment(schedulableTime.startAt).format("YYYY/MM/DD")}
              </p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>予約時間</Label>
            <Col md={8}>
              <p className="form-control-plaintext">
                {moment(schedulableTime.startAt).format("HH:mm")} 〜{" "}
                {moment(schedulableTime.endAt).format("HH:mm")}
              </p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>カウンセラー</Label>
            <Col md={8}>
              <Select
                value={selectedUser}
                options={userOptions()}
                onChange={(value) => setSelectedUser(value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => props.toggle()}>
            キャンセル
          </Button>
          <Button type="submit" color="primary" disabled={!selectedUser}>
            作成
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default NewCounseling
