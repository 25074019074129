import React from "react"
import { ListGroupItem, Row } from "reactstrap"
import { Moment } from "moment"

import SchedulableTime from "./SchedulableTime"
import {
  ScheduleType,
  Customer,
  SchedulableTime as SchedulableTimeModel,
} from "./types"

type Props = {
  type: ScheduleType
  date: Moment
  customer: Customer
  schedulableTimes: SchedulableTimeModel[]
  onChange: () => void
}

const SchedulableTimes: React.FC<Props> = (props) => {
  const { type, date, customer, schedulableTimes } = props

  return (
    <>
      <ListGroupItem>
        <h3>{date.format("YYYY/MM/DD")}</h3>
      </ListGroupItem>
      <ListGroupItem>
        <Row>
          {schedulableTimes.map((schedulableTime) => (
            <SchedulableTime
              key={schedulableTime.startAt.toISOString()}
              type={type}
              customer={customer}
              schedulableTime={schedulableTime}
              onChange={props.onChange}
            />
          ))}
        </Row>
      </ListGroupItem>
    </>
  )
}

export default SchedulableTimes
