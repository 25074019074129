import React, { useState, useEffect } from "react"
import { FormGroup, Row, Col, Input } from "reactstrap"
import {
  MedicalExaminationCategory,
  PrescriptionOption,
  PrescriptionDetail,
} from "../types"
import PrescriptionDetailForm from "./PrescriptionDetailForm"

type Props = {
  index: number
  medicalExaminationId: string
  medicalExaminationCategory: string
  medicalExaminationCategories: Array<MedicalExaminationCategory>
  selectedCategoryNames: Array<string>
  onChange: (
    index: number,
    medicalExaminationCategory: string,
    prescriptionDetails: Array<PrescriptionDetail>
  ) => void
}

const SymptomForm: React.FC<Props> = (props) => {
  const [medicalExaminationCategory, setMedicalExaminationCategory] = useState(
    props.medicalExaminationCategory
  )
  const [prescriptionOptions, setPrescriptionOptions] = useState<
    Array<PrescriptionOption>
  >([])
  const [
    medicalExaminationCategoryId,
    setMedicalExaminationCategoryId,
  ] = useState("")
  const [prescriptionDetails, setPrescriptionDetails] = useState<
    Array<PrescriptionDetail>
  >([])
  const [selectableCategories, setSelectableCategories] = useState<
    Array<MedicalExaminationCategory>
  >([])

  const [cautions, setCautions] = useState<Array<string>>([])

  const updatePrescriptionDetail = (
    newPrescriptionDetail: PrescriptionDetail,
    index: number
  ) => {
    setPrescriptionDetails((prevPrescriptionDetails) =>
      prevPrescriptionDetails.map((prescriptionDetail, i) =>
        i === index ? newPrescriptionDetail : prescriptionDetail
      )
    )
    setCautions(
      Array.from(new Set([...cautions, ...newPrescriptionDetail.cautions]))
    )
  }

  const fetchPrescriptionOptions = async (categoryId: string) => {
    const res = await fetch(
      `/api/medical_examinations/${props.medicalExaminationId}/prescription_options?medical_examination_category_id=${categoryId}`
    )
    const prescriptionOptions = await res.json()
    setPrescriptionOptions(prescriptionOptions)
  }

  const coutionMessages = prescriptionDetails.map((prescriptionDetail) => {
    const filterCautions = cautions.filter((caution) =>
      caution.includes(prescriptionDetail.medicineName)
    )
    if (prescriptionDetail.shouldPrescribe && filterCautions.length) {
      return filterCautions.map((caution, index) => (
        <span className="text-danger" key={index}>
          {caution}
          <br />
        </span>
      ))
    }
  })

  useEffect(() => {
    const category = props.medicalExaminationCategories.find(
      (category) => category.name === medicalExaminationCategory
    )
    if (category && category.id !== "") {
      setMedicalExaminationCategoryId(category.id)
      fetchPrescriptionOptions(category.id)
    }
  }, [medicalExaminationCategory])

  useEffect(() => {
    const prescriptionDetails = prescriptionOptions.map(
      (prescriptionOption) => {
        return {
          medicineName: prescriptionOption.medicine_name,
          shouldPrescribe: false,
          medicineDetailId: null,
          specAmount: null,
          specUnit: prescriptionOption.medicine_details[0].spec_unit,
          dosageKey: "",
          dosage: null,
          dosageUnit: prescriptionOption.dosage_unit,
          useTimesPerDay: null,
          useCase: [],
          day: null,
          dayLabel: null,
          cautions: [],
        }
      }
    )
    setPrescriptionDetails(prescriptionDetails)
  }, [prescriptionOptions])

  useEffect(() => {
    if (!medicalExaminationCategory.length) {
      return
    }
    props.onChange(props.index, medicalExaminationCategory, prescriptionDetails)
  }, [prescriptionDetails])

  useEffect(() => {
    const excludeCategoryNames = props.selectedCategoryNames.filter(
      (name) => name !== medicalExaminationCategory
    )
    const selectableCategories = props.medicalExaminationCategories.filter(
      (category) => !excludeCategoryNames.includes(category.name)
    )
    setSelectableCategories(selectableCategories)
  }, [props.selectedCategoryNames])

  return (
    <>
      <FormGroup>
        <Row className="mb-3">
          <Col md={2} className="col-form-label bg-light">
            診療内容
          </Col>
          <Col md={4}>
            <Input
              type="select"
              onChange={(e) => setMedicalExaminationCategory(e.target.value)}
              value={medicalExaminationCategory}
            >
              {selectableCategories.map((medicalExaminationCategory) => (
                <option
                  key={medicalExaminationCategory.id}
                  value={medicalExaminationCategory.name}
                >
                  {medicalExaminationCategory.name}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>薬名</Col>
          <Col>処方対象</Col>
          <Col>用量</Col>
          <Col>処方量</Col>
          <Col>服用回数</Col>
          <Col md={3}>服用タイミング</Col>
          <Col md={2}>処方日数</Col>
        </Row>
        {prescriptionOptions.map((prescriptionOption, index) => (
          <PrescriptionDetailForm
            prescriptionOption={prescriptionOption}
            key={index}
            index={index}
            medicalExaminationCategoryId={medicalExaminationCategoryId}
            medicalExaminationCategoryIndex={props.index}
            onChange={updatePrescriptionDetail}
          />
        ))}
        <Row className="justify-content-center">
          <div>
            {coutionMessages}
            <span className="text-danger font-weight-bold">
              ※外用薬に関しては1本=30日の処方量で処方
              <br />
            </span>
            ※処方量に関して内服薬は1日量、頓用は一回量、外用薬は処方量を記載
            <br />
            ※頓用時は、処方日数欄に回数を入力
          </div>
        </Row>
      </FormGroup>
    </>
  )
}

export default SymptomForm
