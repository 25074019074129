import React from "react"
import ReactDOM from "react-dom"
import $ from "jquery"
import "bootstrap"
import "cocoon-rails"
import Clipboard from "clipboard"
import Hls from "hls.js"
import Select2 from "../utils/select2"
import FileImageControl from "../utils/file_image_control"
// require("bootstrap/less/bootstrap.less");

import DatePicker from "./components/DatePicker"
import DatetimePicker from "./components/DatetimePicker"
import ShiftManagement from "./components/ShiftManagement"
import SuperShiftManagement from "./components/SuperShiftManagement"
import SelectSchedule from "./components/SelectSchedule"
import UserEditProfile from "./components/UserEditProfile"
import { ScheduleType } from "./components/SelectSchedule/types"

import "stylesheets/application.scss"
import PrescriptionForm from "./components/PrescriptionForm"

window.addEventListener("load", () => {
  new Clipboard(".btn")
  Select2.start()
  FileImageControl.init()

  Array.from(document.getElementsByClassName("date-picker")).forEach((el) => {
    const dataset = (el as HTMLElement).dataset
    ReactDOM.render(
      <DatePicker
        name={dataset.name}
        value={dataset.value}
        className={dataset.class}
      />,
      el
    )
  })

  Array.from(document.getElementsByClassName("datetime-picker")).forEach(
    (el) => {
      const dataset = (el as HTMLElement).dataset
      ReactDOM.render(
        <DatetimePicker
          name={dataset.name}
          value={dataset.value}
          className={dataset.class}
        />,
        el
      )
    }
  )

  const shiftManagement = document.getElementById("shift-management")
  if (shiftManagement) {
    const dataset = (shiftManagement as HTMLElement).dataset
    const config = JSON.parse(dataset.config || "{}")
    ReactDOM.render(
      <ShiftManagement businessHour={config.business_hour} />,
      shiftManagement
    )
  }

  const superShiftManagement = document.getElementById("super-shift-management")
  if (superShiftManagement) {
    const dataset = (superShiftManagement as HTMLElement).dataset
    const config = JSON.parse(dataset.config || "{}")
    // const shifts = SuperShiftManagement;
    // class App extends Component {
    //   constructor(shifts) {
    //     super(shifts);
    //     this.state = {
    //       activePage: 15
    //     };
    //   }
    // }
    ReactDOM.render(
      <SuperShiftManagement businessHour={config.business_hour} />,
      superShiftManagement
    )
  }

  const Profile = document.getElementById("user-edit-profile")
  if (Profile) {
    ReactDOM.render(<UserEditProfile />, Profile)
  }

  const selectCounselingSchedule = document.getElementById(
    "select-counseling-schedule"
  )
  if (selectCounselingSchedule) {
    const dataset = (selectCounselingSchedule as HTMLElement).dataset
    const config = JSON.parse(dataset.config || "{}")
    const prescriptionsData = JSON.parse(dataset.prescriptionsdata || "{}")
    const prescriptions = prescriptionsData.prescriptions
    const currentUserData = JSON.parse(dataset.currentuserdata || "{}")
    const currentUser = currentUserData.currentUser
    const customer = {
      id: config.customer.id,
      name: config.customer.name,
    }
    ReactDOM.render(
      <SelectSchedule
        type={ScheduleType.counseling}
        customer={customer}
        prescriptions={prescriptions}
        currentUser={currentUser}
      />,
      selectCounselingSchedule
    )
  }

  const selectMedicalExaminationSchedule = document.getElementById(
    "select-medical-examination-schedule"
  )
  if (selectMedicalExaminationSchedule) {
    const dataset = (selectMedicalExaminationSchedule as HTMLElement).dataset
    const config = JSON.parse(dataset.config || "{}")
    const prescriptionsData = JSON.parse(dataset.prescriptionsdata || "{}")
    const prescriptions = prescriptionsData.prescriptions
    const currentUserData = JSON.parse(dataset.currentuserdata || "{}")
    const currentUser = currentUserData.currentUser
    const customer = {
      id: config.customer.id,
      name: config.customer.name,
    }
    ReactDOM.render(
      <SelectSchedule
        type={ScheduleType.medicalExamination}
        customer={customer}
        prescriptions={prescriptions}
        currentUser={currentUser}
      />,
      selectMedicalExaminationSchedule
    )
  }

  const selectSideEffectExaminationSchedule = document.getElementById(
    "select-side-effect-examination-schedule"
  )
  if (selectSideEffectExaminationSchedule) {
    const dataset = (selectSideEffectExaminationSchedule as HTMLElement).dataset
    const config = JSON.parse(dataset.config || "{}")
    const prescriptionsData = JSON.parse(dataset.prescriptionsdata || "{}")
    const prescriptions = prescriptionsData.prescriptions
    const currentUserData = JSON.parse(dataset.currentuserdata || "{}")
    const currentUser = currentUserData.currentUser
    const customer = {
      id: config.customer.id,
      name: config.customer.name,
    }
    ReactDOM.render(
      <SelectSchedule
        type={ScheduleType.sideEffectExamination}
        customer={customer}
        prescriptions={prescriptions}
        currentUser={currentUser}
      />,
      selectSideEffectExaminationSchedule
    )
  }

  const prescriptionForm = document.getElementById("prescription-form")
  if (prescriptionForm) {
    const dataset = (prescriptionForm as HTMLElement).dataset
    const mainMedicalExaminationCategory = JSON.parse(
      dataset.mainMedicalExaminationCategory!
    )
    const medicalExaminationCategories = JSON.parse(
      dataset.medicalExaminationCategories || "[]"
    )
    const notPrescribeReason = JSON.parse(dataset.notPrescribeReason || "{}")
    const medicalExaminationId = dataset.medicalExaminationId!
    const forModal = Boolean(dataset.forModal)

    ReactDOM.render(
      <PrescriptionForm
        medicalExaminationId={medicalExaminationId}
        mainMedicalExaminationCategory={mainMedicalExaminationCategory}
        medicalExaminationCategories={medicalExaminationCategories}
        notPrescribeReason={notPrescribeReason}
        forModal={forModal}
      />,
      prescriptionForm
    )
  }

  const videoModal = document.getElementById("video-modal")
  if (videoModal) {
    $("#video-modal").on("show.bs.modal", function (event) {
      const button = event.relatedTarget
      const videoSrc = button.getAttribute("data-source")
      const video = document.getElementById("videoStream") as HTMLVideoElement
      if (Hls.isSupported()) {
        let config = {
          debug: false,
          xhrSetup: function (xhr, url) {
            xhr.withCredentials = true // do send cookie
          },
        }
        let hls = new Hls(config)
        hls.loadSource(videoSrc)
        hls.attachMedia(video)
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc
      }
    })
  }

  const convertDurationToTime = (duration) => {
    if (isNaN(duration)) {
      return "---"
    }

    let flooredDuration = Math.floor(duration)

    let minutes = Math.floor(flooredDuration / 60)
    let seconds = flooredDuration % 60

    return `${minutes}:${seconds}`
  }

  const playButtons = document.querySelectorAll(".play-recording-button")
  playButtons.forEach((button) => {
    let videoSrc = button.getAttribute("data-source")
    if (!videoSrc) {
      return
    }

    let videoElem = document.createElement("video")
    let index = button.getAttribute("data-index")
    let videoDurationElem = document.querySelector(
      `#video_duration_${index}`
    ) as HTMLElement

    if (Hls.isSupported()) {
      let config = {
        debug: false,
        xhrSetup: function (xhr) {
          xhr.withCredentials = true // do send cookie
        },
      }
      let hls = new Hls(config)
      hls.loadSource(videoSrc)
      hls.attachMedia(videoElem)
      hls.on(Hls.Events.INIT_PTS_FOUND, () => {
        videoDurationElem.innerText = convertDurationToTime(videoElem.duration)
      })
    } else if (videoElem.canPlayType("application/vnd.apple.mpegurl")) {
      videoElem.src = videoSrc
    }
  })
})
